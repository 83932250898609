import axios from "axios";
import config from "@/config";

export default axios.create({
 /*  baseURL: "http://127.0.0.1:8000/api", */
  baseURL: config.dev
    ? "https://api.dev.xprexbox.com/api"
    : "https://api.xprexbox.com/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
