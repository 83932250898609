import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/bootstrap-vue";
import api from "./services";
//import { VueMaskDirective } from "v-mask";
//Vue.directive("mask", VueMaskDirective);
import Maska from "maska";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCcVisa, faCcMastercard, faCcAmex, faCcDiscover, faCcDinersClub, faCcJcb } from '@fortawesome/free-brands-svg-icons'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'

library.add(faCcVisa)
library.add(faCcMastercard)
library.add(faCcAmex)
library.add(faCcDiscover)
library.add(faCcDinersClub)
library.add(faCcJcb)
library.add(faCreditCard)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Maska);

Vue.prototype.$googleMapsApiKey = "AIzaSyDxv1_NbBU36zSoj84Y68M2XlUyIl0YZLc";

Vue.config.productionTip = false;

if (store.state.token !== undefined) {
  api.defaults.headers.common.Authorization = `Bearer ${store.state.token}`;
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
