<template>
  <div class="main">
    <b-form @submit="onSubmit" ref="form">
      <div class="h-separator">
        <span></span>
        <span class="title">Informações do pacote</span>
        <span></span>
      </div>

      <b-row>
        <!-- Número do Pacote -->
        <b-col cols="6">
          <b-form-group id="input-add-group-parcel_number">
            <div class="h-separator">
              <label for="input-add-parcel_number" class="title"
                >Número do Pacote *</label
              >
              <span></span>
            </div>

            <b-form-input
              id="input-add-parcel_number"
              v-model="form.parcel_number"
              type="text"
              placeholder="Número do Pacote"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- Identificação do Cliente Externo -->
        <b-col cols="3">
          <b-form-group id="input-add-group-external_customer_id">
            <div class="h-separator">
              <label for="input-add-external_customer_id" class="title"
                >Identificação do Cliente Externo *</label
              >
              <span></span>
            </div>

            <b-form-input
              id="input-add-external_customer_id"
              v-model="form.external_customer_id"
              type="text"
              placeholder="Número do Pacote"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- Serviço -->
        <b-col cols="3">
          <b-form-group id="input-add-group-service">
            <div class="h-separator">
              <label for="input-add-service" class="title">Service *</label>
              <span></span>
            </div>

            <b-form-select
              id="input-add-service"
              v-model="form.service"
              class="mb-3"
            >
              <b-form-select-option :value="undefined"
                >Por favor, selecione uma opção</b-form-select-option
              >
              <b-form-select-option value="BPSI-01"
                >BPS Packet Standard</b-form-select-option
              >
              <b-form-select-option value="BPSI-02"
                >BPS Packet Express</b-form-select-option
              >
              <!-- APENAS PROD       v-if="!config.dev" -->
              <b-form-select-option value="BPS-03"
                >Express Courrier</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>

        <!-- Modalidade Tributária -->
        <b-col cols="3">
          <b-form-group id="input-add-group-tax_modality">
            <div class="h-separator">
              <label for="input-add-tax_modality" class="title"
                >Modalidade Tributária *</label
              >
              <span></span>
            </div>

            <b-form-select
              id="input-add-tax_modality"
              v-model="form.tax_modality"
              class="mb-3"
            >
              <b-form-select-option :value="undefined"
                >Por favor, selecione uma opção</b-form-select-option
              >
              <b-form-select-option value="ddu">DDU</b-form-select-option>
              <b-form-select-option value="ddp">DDP</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <!-- Tipo de Embalagem -->
        <b-col cols="3">
          <b-form-group id="input-add-group-type_of_package">
            <div class="h-separator">
              <label for="input-add-type_of_package" class="title"
                >Tipo de Embalagem *</label
              >
              <span></span>
            </div>

            <b-form-select
              id="input-add-type_of_package"
              v-model="form.type_of_package"
              class="mb-3"
            >
              <b-form-select-option :value="undefined"
                >Por favor, selecione uma opção</b-form-select-option
              >
              <b-form-select-option value="bag"
                >Envelope/Bag</b-form-select-option
              >
              <b-form-select-option value="box">Box</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="h-separator">
        <span></span>
        <span class="title">Informações de envio</span>
        <span></span>
      </div>

      <b-row>
        <!-- Frete -->
        <b-col cols="12">
          <b-form-group id="input-add-group-frete">
            <div class="h-separator">
              <label for="input-add-frete" class="title">Frete *</label>
              <span></span>
            </div>

            <b-form-input
              id="input-add-frete"
              v-model="form.frete"
              type="text"
              placeholder="Frete"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- Insurance -->
        <b-col cols="12">
          <div style="align-items: center">
            Adicionar seguro
            <b-form-checkbox
              v-model="insurance"
              name="check-button"
              switch
              :value="true"
              :unchecked-value="false"
            ></b-form-checkbox>
          </div>
        </b-col>

        <!-- Insurance value -->
        <b-col cols="12" v-if="insurance">
          <b-form-group id="input-add-group-insuranceValue">
            <div class="h-separator">
              <label for="input-add-insuranceValue" class="title"
                >Valor do seguro *</label
              >
              <span></span>
            </div>

            <b-form-input
              id="input-add-insuranceValue"
              v-model="form.insuranceValue"
              type="text"
              placeholder="Valor do seguro"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- Medida - Dimensões * -->
        <b-col cols="6">
          <b-form-group id="input-add-group-selected_unit_dimen">
            <div class="h-separator">
              <label for="input-add-selected_unit_dimen" class="title"
                >Medida - Dimensões *</label
              >
              <span></span>
            </div>

            <b-form-select
              id="input-add-selected_unit_dimen"
              v-model="form.selected_unit_dimen"
              class="mb-3"
            >
              <b-form-select-option :value="undefined"
                >Selecione uma medida</b-form-select-option
              >
              <b-form-select-option value="cm">CM</b-form-select-option>
              <b-form-select-option value="inches">Inches</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <!-- Medida - Peso * -->
        <b-col cols="6">
          <b-form-group id="input-add-group-selected_unit_weight">
            <div class="h-separator">
              <label for="input-add-selected_unit_weight" class="title"
                >Medida - Peso *</label
              >
              <span></span>
            </div>

            <b-form-select
              id="input-add-selected_unit_weight"
              v-model="form.selected_unit_weight"
              class="mb-3"
            >
              <b-form-select-option :value="undefined"
                >Selecione uma medida</b-form-select-option
              >
              <b-form-select-option value="ounce">Ounce</b-form-select-option>
              <b-form-select-option value="gram">Gram</b-form-select-option>
              <b-form-select-option value="pound">Pound</b-form-select-option>
              <b-form-select-option value="kilogram"
                >Kilogram</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>

        <!-- Comprimento -->
        <b-col cols="3">
          <b-form-group id="input-add-group-box-length">
            <div class="h-separator">
              <label for="input-add-box-length" class="title"
                >Comprimento *</label
              >
              <span></span>
            </div>

            <b-form-input
              id="input-add-box-length"
              v-model="form.box['length']"
              type="text"
              placeholder="Comprimento"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- Width -->
        <b-col cols="3">
          <b-form-group id="input-add-group-box-width">
            <div class="h-separator">
              <label for="input-add-box-width" class="title">Largura *</label>
              <span></span>
            </div>

            <b-form-input
              id="input-add-box-width"
              v-model="form.box['width']"
              type="text"
              placeholder="Largura"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- Altura -->
        <b-col cols="3">
          <b-form-group id="input-add-group-box-height">
            <div class="h-separator">
              <label for="input-add-box-height" class="title">Altura *</label>
              <span></span>
            </div>

            <b-form-input
              id="input-add-box-height"
              v-model="form.box['height']"
              type="text"
              placeholder="Altura"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- Peso -->
        <b-col cols="3">
          <b-form-group id="input-add-group-box-weight">
            <div class="h-separator">
              <label for="input-add-box-weight" class="title">Peso *</label>
              <span></span>
            </div>

            <b-form-input
              id="input-add-box-weight"
              v-model="form.box['weight']"
              type="text"
              placeholder="Peso"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col :cols="12">
          <div class="d-flex flex-column align-items-end">
            <b-button class="btnsave" type="submit" variant="primary"
              >Salvar</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import api from "@/services";
import { toast } from "../../utils.js";
import config from "@/config";

export default {
  computed: {
    config() {
      return config;
    },
  },
  props: ["packet"],
  data: () => ({
    form: {
      box: {},
    },
    insurance: true,
  }),
  created() {
    // console.log(this.packet);
    this.form.id = this.packet.id;
    this.form.parcel_number = this.packet.packet_number;
    this.form.external_customer_id = this.packet.external_customer_id;
    this.form.service = this.packet.service;
    this.form.tax_modality = this.packet.tax_modality;
    this.form.type_of_package = this.packet.type_package;
    this.form.insuranceValue = this.packet.insurance_value;
    this.insurance = !!(1 * this.packet.insurance_value);
    this.form.frete = this.packet.freight_value;
    this.form.selected_unit_dimen = this.packet.unit_dimen;
    this.form.selected_unit_weight = this.packet.unit_weight;
    this.form.box["length"] = this.packet.box_lenght;
    this.form.box.width = this.packet.box_width;
    this.form.box.height = this.packet.box_height;
    this.form.box.weight = this.packet.box_weight;

    /*
        external_customer_id: (...)
        id: (...)
        internal_value: (...)
        packet_number: (...)
        paid_out: (...)
        receiver_email: (...)
        receiver_name: (...)
        receiver_telephone: (...)
        sender_email: (...)
        sender_name: (...)
        sender_telephone: (...)
        service: (...)
        tax_modality: (...)
        total: (...)
        type_package: (...)
        url: (...)
        value: (...)
    */
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();

      api.post("packet/update", this.form).then(
        (response) => {
          // console.log(response);
          if ([200, 201, 204].includes(response.status)) {
            // this.$store.dispatch;

            toast(
              this,
              "success",
              "Pacote atualizado com sucesso",
              "Pacote atualizado com sucesso"
            );

            this.$bvModal.hide("edit-packet");
            this.$store.dispatch("packets/edit", response.data.packet);
            this.$emit("finish");
          }
        },
        (error) => {
          toast(
            this,
            "danger",
            "Pacote não atualizado",
            error.response.status === 422
              ? error.response.data.errors.reduce((s, m) => (s += m + " "), "")
              : error.response
          );
          return Promise.reject(error);
        }
      );
      // */
    },
  },
};
</script>
<style lang="stylus">
@import '../../style/main/colors.styl'

.h-separator
    display flex

    .title
        padding 0px 10px
        font-size 14px
        color light
        font-weight bold
        border-radius 1rem
        background primary

    span
        align-self center

    span:last-child
        border 1px dashed primary
        height 1px

    span:last-child
        flex-grow 1

.btnsave
    width 100px
    background-color #4472C4
    font-size 14px
</style>
