<template>
  <div>
    <Interface
      btnAsEvent
      btnLabel="Salvar"
      btnIcon="save"
      :buttonDisabled="isLoading"
      title="Resumo"
      :paths="createPacketPaths"
      noHeader
      separator
      noModal
      @action="onSubmit"
    >
      <!--<div class="h-separator">
        <span></span>
        <span class="title">Remetente</span>
        <span></span>
        <span class="title">Destinatário</span>
        <span></span>
        <span class="title">Detalhes do Pacote</span>
        <span></span>
        <span class="title">Itens</span>
        <span></span>
        <span class="title">Resumo</span>
        <span></span>
      </div>-->
      <div class="m-2">
        <b-row>
          <b-col>
            <div class="info">
              <div class="info-row">
                <span class="span-bold">{{ this.infos.sender.name }}</span>
                <span
                  >{{
                    this.infos.sender["endereco"] +
                    ", " +
                    this.infos.sender["numero"] +
                    (this.infos.sender["complemento"] !== undefined &&
                    this.infos.sender["complemento"] !== null
                      ? ", " + this.infos.sender["complemento"]
                      : "")
                  }}
                </span>
              </div>
              <div class="info-row">
                <span
                  >{{
                    this.infos.sender["cidade"] + ", " + this.infos.sender["uf"]
                  }}
                </span>
                <span>{{ this.infos.sender.telephone }}</span>
                <span>{{ this.infos.sender.email }}</span>
              </div>
            </div>
            <div class="info">
              <div class="info-row">
                <span class="span-bold">{{ this.infos.receiver.name }}</span>
                <span
                  >{{
                    this.infos.receiver["endereco"] +
                    ", " +
                    this.infos.receiver["numero"] +
                    (this.infos.receiver["complemento"] !== undefined &&
                    this.infos.receiver["complemento"] !== null
                      ? ", " + this.infos.receiver["complemento"]
                      : "")
                  }}
                </span>
              </div>
              <div class="info-row">
                <span
                  >{{
                    this.infos.receiver["cidade"] +
                    ", " +
                    this.infos.receiver["uf"]
                  }}
                </span>
                <span>{{ this.infos.receiver.telephone }}</span>
                <span>{{ this.infos.receiver.email }}</span>
              </div>
            </div>
            <div class="info packet">
              <div class="info-row">
                <span>#{{ this.infos.package.external_customer_id }}</span>
                <span>{{ this.infos.package.parcel_number }}</span>
              </div>
              <div class="info-row">
                <span>{{ BPSIDesc }}</span>
                <span>{{ this.infos.package.tax_modality.toUpperCase() }}</span>
                <span>{{
                  this.infos.package["type of_package"]
                    .charAt(0)
                    .toUpperCase() +
                  this.infos.package["type of_package"].slice(1)
                }}</span>
              </div>
              <div class="info-row">
                <span class="span-bold">TOTAL</span>
                <span class="span-bold">$ {{ totalItems.toFixed(2) }}</span>
              </div>
            </div>
            <div class="scroll-container">
              <div class="info packet" v-for="item in items" :key="item.id">
                <div class="info-row">
                  <span class="nowrap">{{ item.description }}</span>
                  <span class="nowrap">{{ item.sku_code }}</span>
                  <span class="nowrap">{{ item.harmonized_Code }}</span>
                </div>
                <div class="info-row">
                  <span>{{ "$ " + (item.value * 1).toFixed(2) }}</span>
                  <span>{{ item.quantity }} x</span>
                  <span>{{
                    "$ " + (item.value * item.quantity).toFixed(2)
                  }}</span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <b-form @submit.stop.prevent="onSubmit" ref="form">
              <div class="info" style="margin: 0; padding: 0; border: none">
                <b-form-group>
                  <div class="h-separator">
                    <label for="input-frete" class="title">Frete *</label>
                    <span></span>
                  </div>
                  <b-form-input
                    id="input-frete"
                    v-model="resume.frete"
                    type="number"
                    step=".01"
                    placeholder="Frete"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="info-row" style="min-height: 73px">
                  <div class="d-flex flex-column">
                    <div class="d-flex flex-row">
                      <div class="pr-2">Adicionar seguro</div>
                      <b-form-checkbox
                        v-model="resume.security"
                        name="check-button"
                        switch
                      ></b-form-checkbox>
                    </div>
                    <div class="d-flex flex-row">
                      <div class="span-bold pr-2">TOTAL</div>
                      <div class="span-bold">{{ "$ " + (this.totalValue || "- - -") }}</div>
                    </div>
                  </div>
                  <!-- <div>
                    <div v-if="insurance" class="h-separator">
                      <label for="input-add-product" class="title">Seguro (%)</label>
                      <span></span>
                    </div>
                    <b-form-input
                      v-if="insurance"
                      id="input-add-product"
                      v-model="resume.insuranceValue"
                      type="number"
                      step="1"
                      placeholder="Valor do Seguro em percentual(%)"
                    ></b-form-input>
                  </div> -->
                </div>
                <span
                  class="pt-2 mt-2 pb-2"
                  style="
                    text-align: center;
                    border-top: 1px dashed #25292d;
                    font-weight: 600;
                    color: #414d55;
                  "
                  >DIMENSÕES</span
                >
                <b-row style="margin-top: 0">
                  <b-col cols="3" md="3">
                    <b-form-group>
                      <div class="h-separator">
                        <label for="input-length" class="title"
                          >Comprimento *</label
                        >
                        <span></span>
                      </div>
                      <div class="d-flex flex-row align-items-center">
                        <b-form-input
                          id="input-length"
                          type="number"
                          v-model="resume.box.length"
                          placeholder="Comprimento"
                          step="0.01"
                          required
                        ></b-form-input>
                        <template
                          v-if="resume.box.length > maxLength"
                        >
                          <span
                            id="comprimento-warning"
                            style="color: #ff1122"
                            class="pl-2 material-icons"
                            >warning</span
                          >
                          <b-tooltip
                            target="comprimento-warning"
                            :title="'Máximo Comprimento (' + maxLength + ' ' + resume.unit_dimen + ')'"
                          ></b-tooltip>
                        </template>
                        <template
                          v-if="resume.box.length < minLength"
                        >
                          <span
                            id="comprimento2-warning"
                            style="color: #ff1122"
                            class="pl-2 material-icons"
                            >warning</span
                          >
                          <b-tooltip
                            target="comprimento2-warning"
                            :title="'Mínimo Comprimento (' + minLength + ' ' + resume.unit_dimen + ')'"
                          ></b-tooltip>
                        </template>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3" md="3">
                    <b-form-group>
                      <div class="h-separator">
                        <label for="input-width" class="title">Largura *</label>
                        <span></span>
                      </div>
                      <div class="d-flex flex-row align-items-center">
                        <b-form-input
                          id="input-width"
                          type="number"
                          v-model="resume.box.width"
                          placeholder="Largura"
                          step="0.01"
                          required
                        ></b-form-input>
                        <template
                          v-if="resume.box.width > maxWidth"
                        >
                          <span
                            id="largura-warning"
                            style="color: #ff1122"
                            class="pl-2 material-icons"
                            >warning</span
                          >
                          <b-tooltip
                            target="largura-warning"
                            :title="'Máximo Largura (' + maxWidth + ' ' + resume.unit_dimen + ')'"
                          ></b-tooltip>
                        </template>
                        <template
                          v-if="resume.box.width < minWidth"
                        >
                          <span
                            id="largura2-warning"
                            style="color: #ff1122"
                            class="pl-2 material-icons"
                            >warning</span
                          >
                          <b-tooltip
                            target="largura2-warning"
                            :title="'Mínimo Largura (' + minWidth + ' ' + resume.unit_dimen + ')'"
                          ></b-tooltip>
                        </template>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3" md="3">
                    <b-form-group>
                      <div class="h-separator">
                        <label for="input-height" class="title">Altura *</label>
                        <span></span>
                      </div>
                      <div class="d-flex flex-row align-items-center">
                        <b-form-input
                          id="input-height"
                          type="number"
                          v-model="resume.box.height"
                          placeholder="Altura"
                          step="0.01"
                          required
                        ></b-form-input>
                        <template
                          v-if="resume.box.height > maxHeight"
                        >
                          <span
                            id="altura-warning"
                            style="color: #ff1122"
                            class="pl-2 material-icons"
                            >warning</span
                          >
                          <b-tooltip
                            target="altura-warning"
                            :title="'Máximo Altura (' + maxHeight + ' ' + resume.unit_dimen + ')'"
                          ></b-tooltip>
                        </template>
                        <template
                          v-if="resume.box.height < minHeight"
                        >
                          <span
                            id="altura2-warning"
                            style="color: #ff1122"
                            class="pl-2 material-icons"
                            >warning</span
                          >
                          <b-tooltip
                            target="altura2-warning"
                            :title="'Mínimo Altura (' + minHeight + ' ' + resume.unit_dimen + ')'"
                          ></b-tooltip>
                        </template>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3" md="3">
                    <b-form-group>
                      <div class="h-separator">
                        <label for="input-unit" class="title">Medida *</label>
                        <span></span>
                      </div>
                      <div class="d-flex flex-row align-items-center">
                        <b-form-select
                          v-model="resume.unit_dimen"
                          :options="options_unit_dimen"
                          required
                        ></b-form-select>
                        <template v-if="limitSumCheck">
                          <span
                            id="dimen-warning"
                            style="color: #ff1122"
                            class="pl-2 material-icons"
                            >warning</span
                          >
                          <b-tooltip
                            target="dimen-warning"
                            :title="'Máximo Dimensão (Comprimento + Largura + Altura) (' + limitSum + ' ' + resume.unit_dimen + ')'"
                          ></b-tooltip>
                        </template>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <span
                  class="pt-2 mt-2 pb-2"
                  style="
                    text-align: center;
                    border-top: 1px dashed #25292d;
                    font-weight: 600;
                    color: #414d55;
                  "
                  >PESO</span
                >
                <b-row style="margin-top: 0">
                  <b-col cols="6" md="6">
                    <b-form-group>
                      <div class="h-separator">
                        <label for="input-weight" class="title">Peso *</label>
                        <span></span>
                      </div>
                      <div class="d-flex flex-row align-items-center">
                        <b-form-input
                          id="input-weight"
                          type="number"
                          v-model="resume.box.weight"
                          placeholder="Peso"
                          step="0.01"
                          required
                        ></b-form-input>
                        <template v-if="limitPesoCheck">
                          <span
                            id="peso-warning"
                            style="color: #ff1122"
                            class="pl-2 material-icons"
                            >warning</span
                          >
                          <b-tooltip
                            target="peso-warning"
                            :title="'Máximo peso (' + limitPeso + ' ' + resume.unit_weight + ')'"
                          ></b-tooltip>
                        </template>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" md="6">
                    <b-form-group>
                      <div class="h-separator">
                        <label for="input-weight" class="title">Medida *</label>
                        <span></span>
                      </div>
                      <b-form-select
                        v-model="resume.unit_weight"
                        :options="options_unit_weight"
                        required
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <span
                  class="pt-2 mt-2 pb-2"
                  style="
                    text-align: center;
                    border-top: 1px dashed #25292d;
                    font-weight: 600;
                    color: #414d55;
                  "
                  ></span
                >
                <b-row v-if="infos.package.service === 'BPSI-03'">
                  <b-col cols="12">
                    <div class="d-flex flex-column">
                      <div class="d-flex flex-row">
                        <div class="pr-2">Adicionar documento(s) CBD</div>
                        <b-form-checkbox
                          v-model="resume.cdb"
                          name="check-button"
                          switch
                        ></b-form-checkbox>
                      </div>
                      <b-form-group v-if="resume.cdb">
                        <b-form-file
                          v-model="resume.cdbDocuments"
                          :state="Boolean(resume.cdbDocuments ? resume.cdbDocuments.length : false)"
                          :browse-text="'Selecionar'"
                          placeholder="Clique aqui selecionar um ou mais arquivos"
                          drop-placeholder="Solte seus arquivos aqui"
                          multiple
                        ></b-form-file>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </div>
    </Interface>
  </div>
</template>

<script>
import api from "@/services";
import { toast } from "@/utils";
import Interface from "@/components/Dashboard/Interface";
export default {
  name: "Home",
  data() {
    return {
      resume: {
        security: false,
        cdb: false,
        cdbDocuments: [],
        // insuranceValue: "",
        frete: "",
        box: {
          height: "",
          length: "",
          width: "",
          weight: "",
        },
        unit_dimen: null,
        unit_weight: null,
      },
      infos: {},
      insurance: false,
      options_unit_dimen: [
        { value: null, text: "Selecione uma medida" },
        {
          value: "cm", 
          text: "CM",
          limit: {
            "BPSI-01": {
              length: {
                min: 16.256,
                max: 59.944
              },
              width: {
                min: 11.176,
                max: 59.944
              },
              height: {
                min: 2.032,
                max: 59.944
              },
              limit_sum: 89.9922
            }, 
            "BPSI-08": {
              length: {
                min: 16.256,
                max: 104.902
              },
              width: {
                min: 11.176,
                max: 104.902
              },
              height: {
                min: 2.032,
                max: 104.902
              },
              limit_sum: 198.12
            },
            "BPSI-02": {
              length: {
                min: 14.986,
                max: 104.902
              },
              width: {
                min: 10.16,
                max: 104.902
              },
              height: {
                min: 2.032,
                max: 104.902
              },
              limit_sum: 198.12
            },
            "BPSI-03": {
              length: {
                min: 14.986,
                max: 104.902
              },
              width: {
                min: 10.16,
                max: 104.902
              },
              height: {
                min: 2.032,
                max: 104.902
              },
              limit_sum: 198.12
            },
            "BPSI-11": {
              length: {
                min: 14.986,
                max: 104.902
              },
              width: {
                min: 10.16,
                max: 104.902
              },
              height: {
                min: 2.032,
                max: 104.902
              },
              limit_sum: 198.12
            },
          } 
        },
        { 
          value: "inches", 
          text: "Inches",
          limit: {
            "BPSI-01": {
              length: {
                min: 6.4,
                max: 23.6
              },
              width: {
                min: 4.4,
                max: 23.6
              },
              height: {
                min: 0.8,
                max: 23.6
              },
              limit_sum: 35.43
            }, 
            "BPSI-08": {
              length: {
                min: 6.4,
                max: 41.3
              },
              width: {
                min: 4.4,
                max: 41.3
              },
              height: {
                min: 0.8,
                max: 41.3
              },
              limit_sum: 78
            },
            "BPSI-02": {
              length: {
                min: 5.9,
                max: 41.3
              },
              width: {
                min: 4,
                max: 41.3
              },
              height: {
                min: 0.8,
                max: 41.3
              },
              limit_sum: 78
            },
            "BPSI-03": {
              length: {
                min: 5.9,
                max: 41.3
              },
              width: {
                min: 4,
                max: 41.3
              },
              height: {
                min: 0.8,
                max: 41.3
              },
              limit_sum: 78
            },
            "BPSI-11": {
              length: {
                min: 5.9,
                max: 41.3
              },
              width: {
                min: 4,
                max: 41.3
              },
              height: {
                min: 0.8,
                max: 41.3
              },
              limit_sum: 78
            },
          }
        },
      ],
      options_unit_weight: [
        { value: null, text: "Selecione uma medida" },
        { 
          value: "ounce", 
          text: "Ounce", 
          limit: {
            "BPSI-01": 176,
            "BPSI-08": 1056,
            "BPSI-02": 1008,
            "BPSI-03": 1008,
            "BPSI-11": 1008
          } 
        },
        { 
          value: "gram", 
          text: "Gram", 
          limit: {
            "BPSI-01": 4989.52,
            "BPSI-08": 29937.1,
            "BPSI-02": 28576.3,
            "BPSI-03": 28576.3,
            "BPSI-11": 28576.3
          }  
        },
        { 
          value: "pound", 
          text: "Pound", 
          limit: {
            "BPSI-01": 11,
            "BPSI-08": 66,
            "BPSI-02": 63,
            "BPSI-03": 63,
            "BPSI-11": 63
          } 
        },
        { 
          value: "kilogram", 
          text: "Kilogram", 
          limit: {
            "BPSI-01": 4.98952,
            "BPSI-08": 29.9371,
            "BPSI-02": 28.5763,
            "BPSI-03": 28.5763,
            "BPSI-11": 28.5763
          }  
        },
      ],
      isLoading: false,
    };
  },
  components: { Interface },
  created() {
    this.infos = this.$store.state.packageSave;
    this.resume = this.$store.state.packageSave.resume || this.resume;
    this.insurance = !!this.resume.insuranceValue;
  },
  watch: {
    resume: {
      deep: true,
      handler(v) {
        this.$store.dispatch("packageSave", {
          ...this.$store.state.packageSave,
          resume: v,
        });
      },
    },
  },
  computed: {
    items() {
      let res = this.infos.items;

      return Object.keys(res).reduce(
        (arr, item) => [
          ...arr,
          Object.assign(
            {},
            this.$store.state.itens.itens.find((i) => i.id === item),
            {
              quantity: res[item].qty,
              value: res[item].value,
            }
          ),
        ],
        []
      );
    },
    totalItems() {
      return this.items.reduce(
        (total, item) => total + item.value * 1 * item.quantity,
        0
      );
    },
    totalValue() {
      //parseInt(this.frete)
      //Number(this.frete)
      //(this.frete * 1)
      return this.resume.frete
        ? (this.totalItems * 1 + this.resume.frete * 1).toFixed(2)
        : null;
    },
    createPacketPaths() {
      return this.$store.state.home.resume.createPacket
        ? [
            { name: "Remetente", route: "sendbox.sender" },
            { name: "Destinatário", route: "sendbox.receiver" },
            { name: "Detalhes do Pacote", route: "sendbox.package" },
            { name: "Itens", route: "sendbox.items" },
            { name: "Resumo", route: "sendbox.resume" },
          ]
        : undefined;
    },
    limitPeso () {
      return this.options_unit_weight.find(
        a => a.value === this.resume.unit_weight
      )?.limit?.[this.infos.package.service] || Number.MAX_SAFE_INTEGER
    },
    limitPesoCheck () {
      return this.resume.box.weight > this.limitPeso
    },
    unitDimenOptionLimit () {
      return this.options_unit_dimen.find(
        a => a.value === this.resume.unit_dimen
      )?.limit?.[this.infos.package.service]
    },
    limitSum () {
      return this.unitDimenOptionLimit?.limit_sum || Number.MAX_SAFE_INTEGER
    },
    maxLength () {
      return this.unitDimenOptionLimit?.length.max || Number.MAX_SAFE_INTEGER
    },
    minLength () {
      return this.unitDimenOptionLimit?.length.min || 0
    },
    maxWidth () {
      return this.unitDimenOptionLimit?.width.max || Number.MAX_SAFE_INTEGER
    },
    minWidth () {
      return this.unitDimenOptionLimit?.width.min || 0
    },
    maxHeight () {
      return this.unitDimenOptionLimit?.height.max || Number.MAX_SAFE_INTEGER
    },
    minHeight () {
      return this.unitDimenOptionLimit?.height.min || 0
    },
    limitSumCheck () {
      return this.resume.box.length * 1 + this.resume.box.width * 1 + this.resume.box.height * 1 > this.limitSum
    },
    BPSIDesc () {
      return ({
        "BPSI-01": "BPSI Prime (Postal)" ,
        "BPSI-08": "BPSI Packet Standard (Postal)",
        "BPSI-02": "BPSI Packet Express (Postal)",
        "BPSI-03": "BPSI Express Plus (Courier)",
        "BPSI-11": "BPSI Tracked Parcel"
      })[this.infos.package.service] || "BPSI Unknown"
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.isLoading = true;
      console.log(this.insurance);
      let data = this.$store.state.packageSave;
      api
        .post("packet/update", {
          sender: data.sender.id,
          receiver: data.receiver.id,
          package: data.package,
          items: data.items,
          resume: {
            ...data.resume,
            total: this.totalValue
          },
        })
        .then(
          (response) => {
            // console.log(response);
            if (response.status === 200) {
              toast(
                this,
                "success",
                "Pacote Atualizado",
                "Pacote criado/alterado com sucesso"
              );
              this.$store.dispatch("packets/addOrEdit", response.data.packet);
              this.$store.dispatch("packageSave", {});
              if(this.resume?.cdbDocuments?.length) {
                const formData = new FormData()
                formData.append('packet_id', response.data?.packet?.id)
                this.resume.cdbDocuments.forEach((file, index) => {
                  formData.append('documents[' + index + ']', file)
                })
                api({method: 'POST', url: 'packet/cdb', data: formData, headers: { 'Content-Type': 'multipart/form-data' }})
                  // .then(
                  //   res => console.log('CDB salvo'), 
                  //   e => console.log('CDB error')
                  // )
              }
              this.isLoading = false;
              this.$router.replace({ path: "/packets_topay" });
            }
          },
          (error) => {
            toast(
              this,
              "danger",
              "Erro",
              error.response.status === 422
                ? Array.isArray(error.response.data)
                  ? error.response.data.reduce(
                      (s, m) => (s += JSON.stringify(m) + " "),
                      ""
                    )
                  : JSON.stringify(
                      error.response.data?.errors || error.response.data
                    )
                : JSON.stringify(error.response)
            );
            this.isLoading = false;
            return Promise.reject(error);
          }
        );
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../style/main/colors.styl';

.info-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  span:first-child:nth-last-child(1) {
    width: 100%;
  }

  span:first-child:nth-last-child(2), span:first-child:nth-last-child(2) ~ span {
    width: 50%;
  }

  span:first-child:nth-last-child(3), span:first-child:nth-last-child(3) ~ span {
    width: calc((100% / 3));
  }

  span:first-child:nth-last-child(4), span:first-child:nth-last-child(4) ~ span {
    width: 25%;
  }

  span:first-child {
    text-align: left;
  }

  span:last-child {
    text-align: right;
  }

  span:not(:first-child):not(:last-child) {
    text-align: center;
  }
}

.span-bold {
  color: primary !important;
  font-weight: 600;
}

.row {
  .col:first-child {
    display: flex;
    flex-direction: column;
    margin-right: 1.5rem;
  }

  .col:nth-child(2) {
    div.info.packet {
      width: 100%;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed greyDark;
  white-space: nowrap;
  margin-bottom: 0.6rem;
  padding-bottom: 0.6em;

  span {
    color: greyLightess;
  }
}

.packet {
  line-height: 1.5rem;
  padding-bottom: 5px;

  div:first-child {
    display: flex;
    justify-content: space-between;

    span:first-child {
      color: primary;
      font-weight: 600;
    }
  }

  div:last-child {
    display: flex;
    justify-content: space-between;
  }
}

.box-select, input, .form-control:focus {
  background: primary;
  color: secondary;
}

.h-separator {
  display: flex;
  margin-bottom: 8px;
  padding-bottom: 0 !important;

  .title {
    padding: 3px 10px;
    font-size: 14px;
    text-transform: uppercase;
    color: grey;
    font-weight: bold;
    border-radius: 1rem;
    background: primary;
  }

  span {
    align-self: center;
  }

  span.title {
    // margin-left 30px
    + span {
      border: 1px dashed greyDark;
      height: 1px;
      width: 50px;
    }
  }

  span:first-child, span:last-child {
    border: 1px dashed greyDark;
    height: 1px;
  }

  span:first-child {
    width: 20px;
  }

  span:last-child {
    flex-grow: 1;
  }
}

.scroll-container {
  height: 200px;
  max-height: 200px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 8px 16px 8px 0px;
  // border 1px dashed
}

.form-group {
  margin-bottom: 0.5rem !important;
}

label {
  margin: 0 !important;
}

.nowrap {
  // word-wrap break-word
  // white-space pre-wrap
  // word-break break-word
  overflow hidden
  text-overflow ellipsis
}
</style>
